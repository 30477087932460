import React from 'react';
import Sidebar from '../Components/Sidebar';
import { Outlet } from 'react-router-dom';

// Styles
import '../Style/Theme/View.css';

function MainPage() {
	return (
		<>
			<Sidebar />
			<div className='wrapper'>
				<Outlet />
			</div>
		</>
	);
}

export default MainPage;
