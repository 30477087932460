import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

function CustomModal({
	variant,
	buttonLabel,
	title,
	className,
	body,
	functionality,
	full,
}) {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<Button className={className} onClick={handleShow} variant={variant}>
				{buttonLabel}
			</Button>

			<Modal
				show={show}
				onHide={handleClose}
				centered
				fullscreen={full}
			>
				<Modal.Header closeButton>
					<Modal.Title style={{ fontSize: '20px' }}>
						{title}
					</Modal.Title>
				</Modal.Header>

				{body && <Modal.Body>{body}</Modal.Body>}

				{functionality && (
					<Modal.Footer className='d-flex flex-row justify-content-center'>
						<Button variant='secondary' onClick={handleClose}>
							No, go back
						</Button>
						<Button variant='danger' onClick={functionality}>
							Yes, proceed
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</>
	);
}

export default CustomModal;
