import React, { useEffect } from 'react';

import { useState } from 'react';
import { jwtDecode } from 'jwt-decode';

import NavigationBar from './NavigationBar';

import '../Style/Settings.css';

// utills
import { notify } from '../Utils/notify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SettingsForm() {
	const [userData, setUserData] = useState({});

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');

	const navItems = [
		{
			title: 'Settings',
			url: '',
		},
	];

	function showSettings() {
		const token = sessionStorage.getItem('access_token');
		const decodedData = jwtDecode(token);

		setUserData(decodedData);
		setUsername(decodedData.username);
		setPassword('');
		setEmail(decodedData['e-mail']);
	}

	useEffect(() => {
		showSettings();
	}, []);

	async function handleSettings(event) {
		event.preventDefault();

		console.log('DECODED DATA', userData);
		const updatedData = {
			username: username,
			password: password,
			email: email,
		};
		console.log('UPDATED DATA', updatedData);

		notify('info', 'Not Implemented yet !!!');

		// const requestBody = {
		// 	user_identifier: userData.sub,
		// 	update_data: updatedData,
		// };
		// const response = await sendPatchRequest('/admin/users', requestBody);
		// console.log(response);
	}

	return (
		<form onSubmit={handleSettings} className='settings-form'>
			<div className='navigation-bar'>
				<NavigationBar items={navItems} />
			</div>
			<div>
				<label>User ID</label>
				<input
					type='text'
					placeholder='User id'
					name='username'
					className='form-control '
					value={userData.sub}
					disabled={true}
					// onChange={(e) => setUsername(e.target.value)}
				/>
			</div>

			<div>
				<label>Username</label>
				<input
					type='text'
					placeholder='Username'
					name='username'
					className='form-control '
					value={username}
					onChange={(e) => setUsername(e.target.value)}
				/>
			</div>

			<div>
				<label>Email</label>
				<input
					type='text'
					placeholder='Email'
					name='email'
					className='form-control '
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>

			<div>
				<label>Password</label>
				<input
					type='password'
					placeholder='Password'
					name='password'
					className='form-control'
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
			</div>

			<div>
				<label>Role</label>
				<input
					type='text'
					placeholder='Role'
					name='role'
					className='form-control'
					value={userData['role']}
					disabled={true}
					// onChange={(e) => setUsername(e.target.value)}
				/>
			</div>

			<div className='d-flex flex-row gap-2 mt-4'>
				<button type='submit' name='settings' className='settings-btn'>
					Update
				</button>
			</div>
			<ToastContainer
				position='top-right'
				hideProgressBar={true}
				autoClose={2000}
				pauseOnHover
				theme='colored'
			/>
		</form>
	);
}

export default SettingsForm;
