import React from 'react';
import HandleOrganizations from '../../Components/HandleOrganizations';
import CustomModal from '../../Components/CustomModal';
import NewOrganizationForm from '../../Components/NewOrganizationForm';
import NavigationBar from '../../Components/NavigationBar';

function OrganizationManagement() {
	const navItems = [
		{
			title: 'Organization Management',
			url: '',
		},
	];
	return (
		<>
			<div className='navigation-bar'>
				<NavigationBar items={navItems} />
			</div>
			<HandleOrganizations />
			<CustomModal
				variant={'success'}
				buttonLabel={
					<div className='d-flex gap-2 justify-content-center'>
						<div>Create</div>
						<i className='bi bi-plus-circle'></i>
					</div>
				}
				title={'Create New Organization'}
				body={<NewOrganizationForm />}
				full={false}
			/>
		</>
	);
}

export default OrganizationManagement;
