import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
// Style

// Utils
import { sendPatchRequest, sendPutRequest } from '../../Utils/sendRequest';
import { capitalizeFirstLetter } from '../../Utils/capitalizeFirst';
import { notify } from '../../Utils/notify';

// Components
import TextEditor from '../../Components/TextEditor';
import NavigationBar from '../../Components/NavigationBar';
import SkeletonLoader from '../../Components/SkeletonLoader';
// notification module
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// datepicker module
import DatePicker from 'react-datepicker';
import { formatDate } from '../../Utils/formatDate';

const defaultReportData = {
	title: '',
	overview: '',
	assessment_type: '',
	date_delivered: '',
	description: '',
	summary: '',
	executive_summary: '',
	summary_of_findings: '',
	exploit: '',
	in_scope: '',
	out_of_scope: '',
	methodology: '',
	recommendations: ''
};

function UserEditReport() {
	const params = useParams();
	const [loading, setLoading] = useState(true);
	const [reportData, setReportData] = useState({});

	const navigationItems = [
		{
			title: 'Reports',
			url: `/user/reports`,
		},
		{
			title: 'Report Details',
			url: `/user/reports/${params.reportId}`,
		},
		{
			title: 'Edit Report',
			url: '',
		},
	];

	useEffect(() => {
		getReportData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getReportData() {
		var privateKey = sessionStorage.getItem('private_key');
		var requestBody = {
			private_key: privateKey,
		};
		var response = await sendPatchRequest(
			`/reports/${params.reportId}`,
			requestBody
		);
		setReportData(response.data);
		setLoading(false);
		console.log('REPORT DATA', response.data);
	}

	const updateReportData = (field, value) => {
		setReportData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	const saveReport = async () => {
		const privateKey = sessionStorage.getItem('private_key');
		const requestBody = {
			private_key: privateKey,
			update_data: {
				title: reportData.title,
				assessment_type: reportData['assessment_type'],
				date_delivered: formatDate(reportData.date_delivered),
				description: reportData['description'],
				summary: reportData['summary'],
				executive_summary: reportData['executive_summary'],
				summary_of_findings: reportData.summary_of_findings,
				exploit: reportData['exploit'],
				in_scope: reportData['in_scope'],
				out_of_scope: reportData['out_of_scope'],
				methodology: reportData['methodology'],
				recommendations: reportData.recommendations,
				overview: reportData.overview
			},
		};
		console.log('REQUEST BODY', requestBody);
		var response = await sendPutRequest(
			`/reports/${params.reportId}`,
			requestBody
		);
		console.log(response);
		if (response.status === 200) {
			notify('success', response.data.message);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	async function exportReport() {
		const requestBody = {
			private_key: sessionStorage.getItem('private_key'),
			template: 'basic',
			export_format: 'pdf',
		};

		var token = sessionStorage.getItem('access_token');

		const response = await axios.post(
			`/reports/${reportData.id}/export`,
			requestBody,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
				responseType: 'blob',
			}
		);

		const blob = new Blob([response.data], { type: 'application/pdf' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'report.pdf');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	}

	return (
		<div>
			<div className='navigation-bar'>
				<NavigationBar items={navigationItems} />
			</div>

			{loading && (
				<SkeletonLoader height={75} count={10} color={'#C0C0C0'} />
			)}
			{!loading && (
				<>
					<div className='d-flex flex-row gap-5 my-auto'>
						<strong>Report Id</strong>
						<div>{reportData.id}</div>
					</div>

					{Object.keys(defaultReportData).map((key, index) => {
						return (
							<div className='d-flex flex-column my-4'>
								<h6>{capitalizeFirstLetter(key)}</h6>
								{key === 'date_delivered' ? (
									<DatePicker
										selected={reportData[key]}
										onChange={(date) =>
											updateReportData(key, date)
										}
										dateFormat='yyyy-MM-dd'
										className='form-control'
									/>
								) : (
									<TextEditor
										value={reportData[key]}
										onValueChange={(value) =>
											updateReportData(key, value)
										}
									/>
								)}
							</div>
						);
					})}

					{/* Another way to render the edit components */}
					<>
						{/* {renderSection('Title', reportData.title, 'title')}
					{renderSection(
						'Assessment Type',
						reportData.assessment_type,
						'assessment_type'
					)}
					{renderSection(
						'Client Id',
						reportData.client_id,
						'client_id'
					)}
					{renderSection(
						'Creation Date',
						reportData.creation_date,
						'creation_date'
					)}
					{renderSection(
						'Date Delivered',
						reportData.date_delivered,
						'date_delivered'
					)}
					{renderSection(
						'Description',
						reportData.description,
						'description'
					)}
					{renderSection('summary', reportData.summary, 'summary')}
					{renderSection(
						'Executive Summary',
						reportData.executive_summary,
						'executive_summary'
					)}
					{renderSection(
						'Summary of Findings',
						reportData.summary_of_findings,
						'summary_of_findings'
					)}
					{renderSection('exploit', reportData.exploit, 'exploit')}
					{renderSection('In Scope', reportData.in_scope, 'in_scope')}
					{renderSection(
						'Out of Scope',
						reportData.out_of_scope,
						'out_of_scope'
					)}
					{renderSection(
						'Methodolodgy',
						reportData.methodology,
						'methodology'
					)}
					{renderSection(
						'Recommendations',
						reportData.recommendations,
						'recommendations'
					)}
					{renderSection('Overview', reportData.overview, 'overview')}
					{renderSection(
						'Security Provider Id',
						reportData.security_provider_id,
						'security_provider_id'
					)}
					{renderSection(
						'Report URL',
						reportData.report_url,
						'report_url'
					)} */}
					</>

					{/* REPORT BUTTONS */}
					<div className='d-flex flex-row gap-2'>
						<button
							onClick={saveReport}
							className='btn btn-success my-3'
						>
							Save
						</button>
						<button
							onClick={exportReport}
							className='btn btn-secondary my-3'
						>
							Export
						</button>
					</div>
					<ToastContainer
						position='top-right'
						hideProgressBar={true}
						autoClose={2000}
						pauseOnHover
						theme='colored'
					/>
				</>
			)}
		</div>
	);
}

export default UserEditReport;

// Loop through report data
// reportData &&
// 	Object.entries(defaultReportData).map(([key, value], index) => {
// 		return (
// 			<div className='d-flex flex-column my-4'>
// 				<h6>{capitalizeFirstLetter(key)}</h6>
// 				<TextEditor
// 					value={reportData[{ key }]}
// 					onValueChange={(value) => updateReportData(key, value)}
// 				/>
// 			</div>
// 		);
// 	});

//  Each Render EditElement
//  <div className='d-flex flex-column my-4'>
// 	<h6>Overview</h6>
// 	<TextEditor
// 		value={reportData['overview']}
// 		onValueChange={(value) => updateReportData('overview', value)}
// 	/>
// </div>
