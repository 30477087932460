import DOMPurify from 'dompurify';

export function sanitizeData(inputHtml, allowedTags) {
	const sanitizedData = DOMPurify.sanitize(inputHtml, {
		ALLOWED_TAGS: [`${allowedTags}`],
	});

	// const sanitizedData = DOMPurify.sanitize(in)

	// console.log(inputHtml);
	return sanitizedData;
}

export const safeHtml = ({ html }) => {
	// eslint-disable-next-line no-unused-vars
	const sanitizedHtml = DOMPurify.sanitize(html);

	return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
