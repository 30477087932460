import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	Outlet,
} from 'react-router-dom';

// Views
import MainPage from '../Views/MainPage';
import Settings from '../Views/Settings';

//auth
import Login from '../Views/Auth/Login';
import Register from '../Views/Auth/Register';

// ----- admin ------
import AdminPanel from '../Views/Admin/AdminPanel';
import AdminReports from '../Views/Admin/AdminReports';
import ReportDetails from '../Views/Admin/Reports/ReportDetails';
import EditReport from '../Views/Admin/Reports/EditReport';
import FindingsDetails from '../Views/Admin/Findings/FindingsDetails';
import EditFinding from '../Views/Admin/Findings/EditFinding';

// Create Report - Finding
import CreateReport from '../Views/Admin/Reports/CreateReport';
import CreateFinding from '../Views/Admin/Findings/CreateFinding';

// ----- user ------
import UserPanel from '../Views/User/UserPanel';
import UserReports from '../Views/User/UserReports';
import UserReportDetails from '../Views/User/UserReportDetails';
import UserEditReport from '../Views/User/UserEditReport';
import UserFindingsDetails from '../Views/User/UserFindingDetails';
import UserEditFinding from '../Views/User/UserEditFinding';
import UserManagement from '../Views/Admin/UserManagement';
import OrganizationManagement from '../Views/Admin/OrganizationManagement';
import UserDetails from '../Views/User/UserDetails';

function AppRouter() {
	return (
		<Router>
			<Routes>
				<Route exact path='/login' element={<Login />} />
				<Route exact path='/register' element={<Register />} />

				<Route element={<ProtectedRoute />}>
					<Route path='/' element={<MainPage />}>
						{/* User Routes */}
						<Route path='/user'>
							<Route path='' element={<UserPanel />} />
							<Route element={<PrivateRoute />}>
								<Route
									path='reports'
									element={<UserReports />}
								/>
								<Route
									path='reports/create'
									element={<CreateReport />}
								/>
								<Route
									path='reports/:reportId'
									element={<UserReportDetails />}
								/>
								<Route
									path='reports/:reportId/edit'
									element={<UserEditReport />}
								/>
								<Route
									path='reports/:reportId/createFinding'
									element={<CreateFinding />}
								/>
								<Route
									path='reports/:reportId/:findingId'
									element={<UserFindingsDetails />}
								/>
								<Route
									path='reports/:reportId/:findingId/edit'
									element={<UserEditFinding />}
								/>
							</Route>
						</Route>

						{/* Admin Routes */}
						<Route path='/admin'>
							<Route path='' element={<AdminPanel />} />
							<Route element={<SystemKeyRoute />}>
								<Route
									path='reports'
									element={<AdminReports />}
								/>
								<Route
									path='reports/create'
									element={<CreateReport />}
								/>
								<Route
									path='reports/:reportId'
									element={<ReportDetails />}
								/>
								<Route
									path='reports/:reportId/edit'
									element={<EditReport />}
								/>
								<Route
									path='reports/:reportId/createFinding'
									element={<CreateFinding />}
								/>
								<Route
									path='reports/:reportId/:findingId'
									element={<FindingsDetails />}
								/>
								<Route
									path='reports/:reportId/:findingId/edit'
									element={<EditFinding />}
								/>
								<Route
									path='users'
									element={<UserManagement />}
								/>
								<Route
									path='user/:userId'
									element={<UserDetails />}
								/>
								<Route
									path='organizations'
									element={<OrganizationManagement />}
								/>
							</Route>
						</Route>

						<Route path='/settings' element={<Settings />} />
					</Route>
				</Route>
			</Routes>
		</Router>
	);
}

function ProtectedRoute() {
	// var token = getAuthed();
	var jwt = sessionStorage.getItem('access_token');

	if (jwt) {
		return <Outlet />;
	} else {
		return <Navigate to='/login' />;
	}
}

//admin system key
function SystemKeyRoute() {
	const systemKey = sessionStorage.getItem('system_key');

	if (systemKey) {
		return <Outlet />;
	} else {
		return <Navigate to='/admin' />;
	}
}

function PrivateRoute() {
	var privateKey = sessionStorage.getItem('private_key');

	if (privateKey) {
		return <Outlet />;
	} else {
		return <Navigate to='/user' />;
	}
}

export default AppRouter;
