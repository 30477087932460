import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPostRequest } from '../../Utils/sendRequest';
import { notify } from '../../Utils/notify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../Style/AuthComponents.css';

function Login() {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const loginData = {
        username: username,
        password: password,
    };

    const handleLogin = async (event) => {
        event.preventDefault();

		// Validate fields
        if (!username || !password) {
            notify('error', 'Username and password are required.');
            return;
        }

		// Check for spaces in the username
		if (/\s/.test(username)) {
			notify('error', 'Username cannot contain spaces.');
			return;
		}

        setIsLoading(true);

        try {
            var response = await sendPostRequest('/auth/login', loginData);

            if (response?.status === 201) {
                var jwt = response.data.access_token;
                sessionStorage.setItem('access_token', jwt);
                navigate('/');
            } else if (response?.status === 401) {
                notify('error', response.data.message || 'Login failed. Please try again later.');
            } else {
                notify('error', 'Login failed. Please try again later.');
            }
        } catch (error) {
			notify('error', 'Login failed. Please try again later.');
        } finally {
			setIsLoading(false);
		}
    };

    return (
        <>
            <div className='login-wrapper'>
                <form onSubmit={handleLogin} className='login-form'>
                    <div className='logo'>GWRX Platform</div>
                    <div className='login-controls'>
                        <input
                            type='text'
                            placeholder='Username or email'
                            name='username'
                            className='form-control py-2'
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            disabled={isLoading} // Disable input when loading
                        />
                        <input
                            type='password'
                            placeholder='Password'
                            name='password'
                            className='form-control py-2 mb-5 mt-2'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={isLoading} // Disable input when loading
                        />
                        <button
                            type='submit'
                            name='login'
                            className='login-btn'
                            disabled={isLoading} // Disable button when loading
                        >
                            {isLoading ? (
                                <>
                                Logging in <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                </>
                            ) : 'Login'}
                        </button>
                        <div className='footer'>
                            <span onClick={() => navigate('/register')}>
                                Sign up
                            </span>
                            <span>Forgot Password?</span>
                        </div>
                    </div>
                </form>
            </div>
            <ToastContainer
                position='top-right'
                autoClose={2000}
                pauseOnHover
                theme='colored'
            />
        </>
    );
}

export default Login;