import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { sendPutRequest, sendPatchRequest } from '../../Utils/sendRequest';

import TextEditor from '../../Components/TextEditor';
import { IconWithTooltip } from '../../Utils/IconWithTooltip';
import { sanitizeData } from '../../Utils/sanitzeData';

import { Form } from 'react-bootstrap';

import NavigationBar from '../../Components/NavigationBar';

import { notify } from '../../Utils/notify';
// notification
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const severityOptions = ['Info', 'Low', 'Medium', 'High', 'Critical'];

function UserEditFinding() {
	const params = useParams();
	const navigationItems = [
		{
			title: 'Reports',
			url: `/user/reports`,
		},
		{
			title: 'Report Details',
			url: `/user/reports/${params.reportId}`,
		},
		{
			title: 'Finding Details',
			url: `/user/reports/${params.reportId}/${params.findingId}`,
		},
		{
			title: 'Edit Finding',
			url: '',
		},
	];
	// const location = useLocation();
	// const findingDataPassed = location.state?.finding;

	const [findingData, setFindingData] = useState({});
	//const [selectedOption, setSelectedOption] = useState(findingData.severity);

	useEffect(() => {
		getFindingData();
		// setFindingData(findingDataPassed);
		// setSelectedOption(findingDataPassed['severity']);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateFindingData = (field, value) => {
		setFindingData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	async function getFindingData() {
		const privateKey = sessionStorage.getItem('private_key');
		var requestBody = {
			private_key: privateKey,
		};
		// console.log(params);
		var response = await sendPatchRequest(
			`/reports/${params.reportId}/findings/${params.findingId}`,
			requestBody
		);
		setFindingData(response.data);
		// setLoading(false);
		console.log('RESPONSE', response.data);
	}

	const saveFindings = async () => {
		console.log('Content saved:', findingData);
		const privateKey = sessionStorage.getItem('private_key');
		const requestBody = {
			private_key: privateKey,
			update_data: {
				attack_chain: findingData['attack_chain'],
				attack_scenario: findingData['attack_scenario'],
				cvss_score: sanitizeData(findingData['cvss_score'], ''),
				cwe: findingData['cwe'],
				description: findingData['description'],
				impact: findingData['impact'],
				location: findingData['location'],
				recommendation: findingData['recommendation'],
				references: findingData['references'],
				severity: findingData['severity'],
				title: findingData['title'],
			},
		};

		console.log('REQUEST', requestBody);

		var response = await sendPutRequest(
			`/reports/${params.reportId}/findings/${params.findingId}`,
			requestBody
		);

		console.log('RESPONSE', response);
		if (response.status === 200) {
			notify('success', response.data.message);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	function renderSection(label, data, key) {
		return (
			<div className='d-flex flex-column my-2'>
				<h6>{label}</h6>
				<TextEditor
					value={data}
					onValueChange={(value) => updateFindingData(key, value)}
				/>
			</div>
		);
	}

	return (
		<div className='report-details-wrapper'>
			{/* Finding navigation */}
			<div className='navigation-bar'>
				<NavigationBar items={navigationItems} />
			</div>

			{/* Edit Finding */}
			{findingData && (
				<>
					<div className='d-flex flex-row gap-5'>
						<h6>Finding Id</h6>
						<div>{findingData.id}</div>
					</div>
					{renderSection('Title', findingData.title, 'title')}

					{/* Severity */}
					{findingData.severity && (
						<div className='d-flex flex-row gap-3'>
							<h6 className='my-auto'>Select Severity: </h6>
							<Form.Control
								as='select'
								style={{ width: '200px' }}
								value={findingData.severity}
								onChange={(event) => {
									const selectedValue = event.target.value;
									// setSelectedOption(selectedValue);
									updateFindingData(
										'severity',
										selectedValue
									);
								}}
							>
								{severityOptions.map((option, index) => {
									return (
										<option key={index} value={option}>
											{option}
										</option>
									);
								})}
							</Form.Control>
						</div>
					)}
					{/* CVSS Score */}
					<div className='d-flex flex-column my-2'>
						<div className='d-flex flex-row gap-3'>
							<h6>CVSS Score</h6>
							<h6 className='cvss-score-info'>
								<IconWithTooltip
									icon={<i className='bi bi-info-circle'></i>}
									message={'Accepted Values: 0.0 - 10.0'}
								/>
							</h6>
						</div>
						<TextEditor
							// value={`${sanitizeData(findingData['cvss_score'])}`}
							value={String(findingData['cvss_score'])}
							onValueChange={(value) =>
								updateFindingData('cvss_score', value)
							}
						/>
					</div>
					{renderSection(
						'Description',
						findingData.description,
						'description'
					)}
					{renderSection(
						'Attack Scenario',
						findingData.attack_scenario,
						'attack_scenario'
					)}
					{renderSection(
						'Attack Chain',
						findingData.attack_chain,
						'attack_chain'
					)}
					{renderSection(
						'Location',
						findingData.location,
						'location'
					)}
					{renderSection('Cwe', findingData.cwe, 'cwe')}
					{renderSection(
						'Recommendation',
						findingData.recommendation,
						'recommendation'
					)}
					{renderSection(
						'References',
						findingData.references,
						'references'
					)}

					<div className='d-flex flex-row gap-2'>
						<button
							onClick={saveFindings}
							className='btn btn-success my-3'
						>
							Save
						</button>
					</div>
					<ToastContainer
						position='top-right'
						hideProgressBar={true}
						autoClose={2000}
						pauseOnHover
						theme='colored'
					/>
				</>
			)}
		</div>
	);
}

export default UserEditFinding;