import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// Style
import '../Style/Reports.css';
import '../Style/Theme/Button.css';

//components
import CustomModal from './CustomModal.js';
// notification module
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//  Utills
import { sendDeleteRequest, sendPostRequest } from '../Utils/sendRequest';
import { sanitizeData } from '../Utils/sanitzeData';
import { notify } from '../Utils/notify.js';

function Report({ report }) {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    const [disableBtn, setDisableBtn] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    function showDetails() {
        path.startsWith('/admin')
            ? navigate(`/admin/reports/${report.id}`)
            : navigate(`/user/reports/${report.id}`);
    }

    function editReport() {
        path.startsWith('/admin')
            ? navigate(`/admin/reports/${report.id}/edit`)
            : navigate(`/user/reports/${report.id}/edit`);
    }

    async function deleteReport() {
        setDisableBtn(true);
        var response = null;
        if (path.startsWith('/admin')) {
            response = await sendDeleteRequest(`/admin/reports/${report.id}`);
        } else {
            response = await sendDeleteRequest(`/reports/${report.id}`);
        }
        console.log(response);
        if (response.status === 200) {
            notify('success', response.data.message);
            setTimeout(() => window.location.reload(), 1000);
        }
    }

    async function exportReport(format) {
        setDisableBtn(true);
        setExporting(true);
        const key = path.startsWith('/admin')
            ? sessionStorage.getItem('system_key')
            : sessionStorage.getItem('private_key');
        
        const requestBody = {
            private_key: key,
            template: 'report',
            export_format: format
        };

        var response = null;
        if (path.startsWith('/admin')) {
            response = await sendPostRequest(`/admin/reports/${report.id}/export`, requestBody);
        } else {
            response = await sendPostRequest(`/reports/${report.id}/export`, requestBody);
        }

        const blob = new Blob([response.data], { type: `application/${format}` });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report-${report.id}.${format}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        setDisableBtn(false);
        setExporting(false);
    }

    return (
        <>
            <div className='report'>
                {/* Report Basic Details */}
                <div
                    className='report-title d-flex flex-row justify-content-between'
                    onClick={showDetails}
                >
                    <div>{sanitizeData(report.title)}</div>
                </div>
                <hr />
                <div className='report-content'>
                    <label>Report ID:</label>
                    <div>{report.id}</div>
                </div>
                <div className='report-content'>
                    <label>Type:</label>
                    <div>
                        {report && sanitizeData(report['assessment_type'])}
                    </div>
                </div>
                <div className='report-content'>
                    <label>Findings:</label>
                    <div>{report['related_findings']}</div>
                </div>
                <div className='report-content'>
                    <label>Date created: </label>
                    <div>{report['creation_date']}</div>
                </div>
                <div className='report-content'>
                    <label>Date delivered</label>
                    <div>{report['date_delivered']}</div>
                </div>

                <hr />
                <div className='d-flex gap-2 mt-2 buttons-wrapper'>
                    <Button variant='secondary' className='mb-3' onClick={showDetails}>
                        Details
                    </Button>
                    <Button variant='secondary' className='mb-3' onClick={editReport}>
                        Edit
                    </Button>
                    <DropdownButton data-bs-theme="dark"
                        title={exporting ? (
                            <>
                                Exporting <Spinner as="span" animation="border" size="sm" />
                            </>
                        ) : 'Export'}
                        variant='secondary'
                        className='mb-3'
                        disabled={disableBtn}
                        show={!exporting && dropdownVisible}
                        onClick={() => setDropdownVisible(!dropdownVisible)}
                    >
                        <Dropdown.Item onClick={() => exportReport('pdf')}>Export as PDF</Dropdown.Item>
                        <Dropdown.Item onClick={() => exportReport('html')}>Export as HTML</Dropdown.Item>
                        <Dropdown.Item onClick={() => exportReport('json')}>Export as JSON</Dropdown.Item>
                    </DropdownButton>
                    <CustomModal
                        buttonLabel={<div>Delete</div>}
                        className={'btn btn-danger d-flex gap-2 mb-3'}
                        title='Confirm report deletion'
                        body='This action is irreversible. Do you want to proceed?'
                        functionality={deleteReport}
                    />
                </div>

                <ToastContainer
                    position='top-right'
                    hideProgressBar={true}
                    autoClose={2000}
                    pauseOnHover
                    theme='colored'
                />
            </div>
        </>
    );
}

export default Report;