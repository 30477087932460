import React from 'react';
import { useState } from 'react';
import { sendPostRequest } from '../Utils/sendRequest';
import '../Style/UserForm.css';
import { Form } from 'react-bootstrap';

import { notify } from '../Utils/notify';

// notification
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NewUserForm() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');
	const [role, setRole] = useState('');
	const [disableBtn, setDisableBtn] = useState(false);

	const roles = ['PlatformAdmin', 'User'];

	async function createNewUser(e) {
		e.preventDefault();
		const userData = {
			username: username,
			password: password,
			email: email,
			role: role,
		};

		const response = await sendPostRequest('/admin/users', userData);

		if (response.status === 201) {
			setDisableBtn(true);
			notify('success', response.data.message);
			setTimeout(() => window.location.reload(), 2000);
		} else {
			notify('error', response.data.message);
		}
	}

	return (
		<>
			<form onSubmit={createNewUser} className='user-form'>
				<div className='d-flex flex-column gap-2'>
					<label>Username</label>

					<Form.Control
						type='text'
						placeholder='Username'
						name='username'
						className='form-control py-2'
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
					<label>Password</label>
					<Form.Control
						type='password'
						placeholder='Password'
						name='password'
						className='form-control py-2'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<label>Email</label>
					<Form.Control
						type='text'
						placeholder='email'
						name='email'
						className='form-control py-2'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<label>Role</label>

					<Form.Control
						as='select'
						value={role}
						onChange={(event) => {
							const selectedValue = event.target.value;
							setRole(selectedValue);
						}}
					>
						{roles.map((option, index) => {
							return (
								<option key={index} value={option}>
									{option}
								</option>
							);
						})}
					</Form.Control>
				</div>

				<button
					type='submit'
					name='login'
					className='theme-btn'
					disabled={disableBtn}
				>
					Create
				</button>
			</form>
			<ToastContainer
				position='top-right'
				hideProgressBar={false}
				autoClose={2000}
				pauseOnHover
				theme='colored'
			/>
		</>
	);
}

export default NewUserForm;
