import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({ value, onValueChange }) => {
	const [editorValue, setEditorValue] = useState('');
	const quillRef = useRef(null);

	useEffect(() => {
		if (quillRef.current) {
			setEditorValue(value);
		}

		// const parsedValue = parseInt(value, 10);
		// if (!isNaN(parsedValue)) {
		// 	onValueChange(parsedValue);
		// }
	}, [value]);

	const handleChange = (text) => {
		setEditorValue(text);
		onValueChange(text);
	};

	return (
		<div>
			<ReactQuill
				ref={quillRef}
				theme='snow'
				modules={modules}
				formats={formats}
				// defaultValue={value}
				value={editorValue}
				onChange={handleChange}
			/>
		</div>
	);
};

export default TextEditor;

const modules = {
	toolbar: [
		[{ header: '1' }, { header: '2' }, { font: [] }],
		[{ size: [] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['link', 'image'],
		['clean'],
	],
};

const formats = [
	'header',
	'font',
	'size',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'link',
	'image',
];
