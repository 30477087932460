import React from 'react';
import { useNavigate } from 'react-router-dom';

function NavigationBar({ items }) {
	const navigate = useNavigate();

	return (
		<>
			{items.map((item, index, array) => {
				const isLastItem = index === array.length - 1;

				return (
					<React.Fragment key={index}>
						<div
							className={`navigation-item ${
								isLastItem ? 'active' : ''
							}`}
							onClick={() => navigate(item.url)}
						>
							{item.title}
						</div>
						<i
							className={`my-auto ${
								!isLastItem ? 'bi bi-chevron-right' : ''
							}`}
						></i>
					</React.Fragment>
				);
			})}
		</>
	);
}

export default NavigationBar;
