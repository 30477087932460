import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function SkeletonLoader({ height, count, color }) {
	return (
		<SkeletonTheme highlightColor={color}>
			<Skeleton height={height} count={count} />
		</SkeletonTheme>
	);
}

export default SkeletonLoader;