import AppRouter from './Routes/Routes';

// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

//css
import '../src/Style/Theme/View.css';

function App() {
	return <AppRouter />;
}

export default App;
