import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../Style/AuthComponents.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { notify } from '../../Utils/notify';

function SystemKeyAuth() {

	const navigate = useNavigate();
	const [systemKey, setSystemKey] = useState('');

	async function getAuthed(e) {
		e.preventDefault();

		// Check if the private key is empty and over 128 characters
		if (systemKey === '') {
			notify('error', 'System key cannot be empty.');
			return;
		}

		if (systemKey.length < 4096) {
			notify('error', 'System key is too short.');
			return;
		}
		
		sessionStorage.setItem('system_key', systemKey);
		navigate('/admin/reports');
	}

	return (
		<>
			<form className='access-form'>
				<div className='d-flex flex-column justify-content-center'>
					<h2 className='mx-auto'>Access required</h2>
					<h6 className='mx-auto'>
						Please enter the system key required to decrypt reports.
					</h6>
				</div>

				<div>
					<input
						type='password'
						autoComplete='new-password'
						placeholder='System key...'
						name='privateKey'
						className='form-control my-4 py-2'
						value={systemKey}
						onChange={(e) => setSystemKey(e.target.value)}
					/>
					<button
						type='submit'
						name='access'
						className='access-btn'
						onClick={getAuthed}
					>
						Get Access
					</button>
				</div>
			</form>
			<ToastContainer
				position='top-right'
				hideProgressBar={true}
				pauseOnHover
				theme='colored'
			/>
		</>
	);
}

export default SystemKeyAuth;
