import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// utils
import { sendPutRequest, sendPatchRequest } from '../../../Utils/sendRequest';
import { IconWithTooltip } from '../../../Utils/IconWithTooltip';
import { sanitizeData } from '../../../Utils/sanitzeData';
import { notify } from '../../../Utils/notify';

// bootstrap
import { Form } from 'react-bootstrap';

// style
import '../../../Style/Table.css';

// components
import NavigationBar from '../../../Components/NavigationBar';
import TextEditor from '../../../Components/TextEditor';

// notification
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// SEVERITY OPTIONS
const severityOptions = ['Info', 'Low', 'Medium', 'High', 'Critical'];

function EditFinding() {
	const params = useParams();
	const navigationItems = [
		{
			title: 'Reports',
			url: '/admin/reports',
		},
		{
			title: 'Report Details',
			url: `/admin/reports/${params.reportId}`,
		},
		{
			title: 'Finding Details',
			url: `/admin/reports/${params.reportId}/${params.findingId}`,
		},
		{
			title: 'Edit Finding',
			url: '',
		},
	];
	const [findingData, setFindingData] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [selectedOption, setSelectedOption] = useState();

	useEffect(() => {
		getFindingData();
		// setFindingData(findingDataPassed);
		// setSelectedOption(findingDataPassed['severity']);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (findingData) {
			setSelectedOption(findingData.severity);
		}
	}, [findingData]);

	const updateFindingData = (field, value) => {
		setFindingData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};
	async function getFindingData() {
		var systemKey = sessionStorage.getItem('system_key');
		var requestBody = {
			private_key: systemKey,
		};
		var response = await sendPatchRequest(
			`/admin/reports/${params.reportId}/findings/${params.findingId}`,
			requestBody
		);
		setFindingData(response.data);
		console.log('RESPONSE', response.data);

		// setLoading(false);
	}
	const saveFindings = async () => {
		console.log('Content saved:', findingData);
		const systemKey = sessionStorage.getItem('system_key');
		const requestBody = {
			private_key: systemKey,
			update_data: {
				// id: findingData.id,
				attack_chain: findingData['attack_chain'],
				attack_scenario: findingData['attack_scenario'],
				cvss_score: sanitizeData(findingData['cvss_score'], ''),
				cwe: findingData['cwe'],
				description: findingData['description'],
				impact: findingData['impact'],
				location: findingData['location'],
				recommendation: findingData['recommendation'],
				references: findingData['references'],
				severity: findingData['severity'],
				title: findingData['title'],
			},
		};
		console.log('REQUEST', requestBody);
		var response = await sendPutRequest(
			`/admin/reports/${params.reportId}/findings/${params.findingId}`,
			requestBody
		);
		console.log('RESPONSE', response);
		if (response.status === 200) {
			notify('success', response.data.message);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	return (
		<div className='report-details-wrapper'>
			{/* Finding navigation */}
			<div className='navigation-bar'>
				<NavigationBar items={navigationItems} />
			</div>

			{/* Edit Finding */}
			{findingData && (
				<>
					<div className='d-flex flex-row gap-5'>
						<h6>Finding Id</h6>
						<div>{findingData.id}</div>
					</div>
					<div className='d-flex flex-column my-2'>
						<h6>Title</h6>
						<TextEditor
							value={findingData['title']}
							onValueChange={(value) =>
								updateFindingData('title', value)
							}
						/>
					</div>
					{/* Severity */}
					{findingData.severity && (
						<div className='d-flex flex-row gap-3'>
							<h6 className='my-auto'>Select Severity: </h6>
							<Form.Control
								as='select'
								style={{ width: '200px' }}
								value={findingData.severity}
								onChange={(event) => {
									const selectedValue = event.target.value;
									// setSelectedOption(selectedValue);
									updateFindingData(
										'severity',
										selectedValue
									);
								}}
							>
								{severityOptions.map((option, index) => {
									return (
										<option key={index} value={option}>
											{option}
										</option>
									);
								})}
							</Form.Control>
						</div>
					)}
					{/* CVSS Score */}
					<div className='d-flex flex-column my-2'>
						<div className='d-flex flex-row gap-3'>
							<h6>CVSS Score</h6>
							<h6 className='cvss-score-info'>
								<IconWithTooltip
									icon={<i className='bi bi-info-circle'></i>}
									message={'Accepted Values: 0.0 - 10.0'}
								/>
							</h6>
						</div>
						<TextEditor
							// value={`${sanitizeData(findingData['cvss_score'])}`}
							value={String(findingData['cvss_score'])}
							onValueChange={(value) =>
								updateFindingData('cvss_score', value)
							}
						/>
					</div>

					<div className='d-flex flex-column my-2'>
						<h6>Description</h6>
						<TextEditor
							value={findingData['description']}
							onValueChange={(value) =>
								updateFindingData('description', value)
							}
						>
							{/* <div
								dangerouslySetInnerHTML={{
									__html: findingData['description'],
								}}
							/> */}
						</TextEditor>
					</div>

					<div className='d-flex flex-column my-2'>
						<h6>Attack Scenario</h6>
						<TextEditor
							value={findingData['attack_scenario']}
							onValueChange={(value) =>
								updateFindingData('attack_scenario', value)
							}
						/>
					</div>

					<div className='d-flex flex-column my-2'>
						<h6>Attack Chain</h6>
						<TextEditor
							value={findingData['attack_chain']}
							onValueChange={(value) =>
								updateFindingData('attack_chain', value)
							}
						/>
					</div>

					<div className='d-flex flex-column my-2'>
						<h6>Location</h6>
						<TextEditor
							value={findingData['location']}
							onValueChange={(value) =>
								updateFindingData('location', value)
							}
						/>
					</div>

					<div className='d-flex flex-column my-2'>
						<h6>Impact</h6>
						<TextEditor
							value={findingData['impact']}
							onValueChange={(value) =>
								updateFindingData('impact', value)
							}
						/>
					</div>

					<div className='d-flex flex-column my-2'>
						<h6>CWE</h6>
						<TextEditor
							value={findingData['cwe']}
							onValueChange={(value) =>
								updateFindingData('cwe', value)
							}
						/>
					</div>

					<div className='d-flex flex-column my-2'>
						<h6>Recommendation</h6>
						<TextEditor
							value={findingData['recommendation']}
							onValueChange={(value) =>
								updateFindingData('recommendation', value)
							}
						/>
					</div>

					<div className='d-flex flex-column my-2'>
						<h6>References</h6>
						<TextEditor
							value={findingData['references']}
							onValueChange={(value) =>
								updateFindingData('references', value)
							}
						/>
					</div>

					<div className='d-flex flex-row gap-2'>
						<button
							onClick={saveFindings}
							className='btn btn-success my-3'
						>
							Save
						</button>
					</div>
					<ToastContainer
						position='top-right'
						hideProgressBar={true}
						autoClose={2000}
						pauseOnHover
						theme='colored'
					/>
				</>
			)}
		</div>
	);
}

export default EditFinding;
