import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Components
import AccessUsers from '../../../Components/AccessUsers';
import TextEditor from '../../../Components/TextEditor';
import NavigationBar from '../../../Components/NavigationBar';
import SkeletonLoader from '../../../Components/SkeletonLoader';
// datepicker module
import DatePicker from 'react-datepicker';
// notification
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Utils
import { sendPatchRequest, sendPutRequest } from '../../../Utils/sendRequest';
import { notify } from '../../../Utils/notify';
import { capitalizeFirstLetter } from '../../../Utils/capitalizeFirst';
import { formatDate } from '../../../Utils/formatDate';

// Styling
import '../../../Style/Table.css';

const defaultReportData = {
	title: '',
	overview: '',
	assessment_type: '',
	date_delivered: '',
	description: '',
	summary: '',
	executive_summary: '',
	summary_of_findings: '',
	exploit: '',
	in_scope: '',
	out_of_scope: '',
	methodology: '',
	recommendations: '',
};

function EditReport() {
	const params = useParams();
	const [reportData, setReportData] = useState(defaultReportData);
	const [loading, setLoading] = useState(true);

	const navigationItems = [
		{
			title: 'Reports',
			url: '/admin/reports',
		},
		{
			title: 'Report Details',
			url: `/admin/reports/${params.reportId}`,
		},
		{
			title: 'Edit Report',
			url: ``,
		},
	];

	useEffect(() => {
		getReportData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getReportData() {
		var systemKey = sessionStorage.getItem('system_key');
		var requestBody = {
			private_key: systemKey,
		};

		var response = await sendPatchRequest(
			`/admin/reports/${params.reportId}`,
			requestBody
		);
		setReportData(response.data);
		setLoading(false);
		console.log('REPORT DETAILS - REPORT DATA:', response);
	}

	const updateReportData = (field, value) => {
		setReportData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	const saveReport = async () => {
		const systemKey = sessionStorage.getItem('system_key');
		const requestBody = {
			private_key: systemKey,
			update_data: {
				title: reportData.title,
				assessment_type: reportData['assessment_type'],
				date_delivered: formatDate(reportData.date_delivered),
				overview: reportData['overview'],
				description: reportData['description'],
				summary: reportData['summary'],
				executive_summary: reportData['executive_summary'],
				exploit: reportData['exploit'],
				in_scope: reportData['in_scope'],
				out_of_scope: reportData['out_of_scope'],
				methodology: reportData['methodology'],
				recommendations: reportData.recommendations,
				summary_of_findings: reportData.summary_of_findings
			},
		};

		console.log('REQUEST SENT', requestBody);

		const response = await sendPutRequest(
			`/admin/reports/${params.reportId}`,
			requestBody
		);

		console.log('RESPONSE', response);

		if (response.status === 200) {
			notify('success', response.data.message);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	async function exportReport() {
		const requestBody = {
			private_key: sessionStorage.getItem('system_key'),
			template: 'report',
			export_format: 'pdf',
		};

		var token = sessionStorage.getItem('access_token');

		const response = await axios.post(
			`/admin/reports/${reportData.id}/export`,
			requestBody,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
				responseType: 'blob',
			}
		);

		notify('success', response.data.message);

		if (response.status === 200) {
			notify('success', response.data.message);
		}

		const blob = new Blob([response.data], { type: 'application/pdf' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'report.pdf');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	}

	return (
		<div>
			<div className='navigation-bar'>
				<NavigationBar items={navigationItems} />
			</div>

			{loading && (
				<SkeletonLoader height={75} count={10} color={'#C0C0C0'} />
			)}

			{!loading && (
				<>
					<div className='d-flex flex-row gap-5 my-auto'>
						<strong>Report Id</strong>
						<div>{reportData.id}</div>
					</div>

					<div className='d-flex flex-column my-2'>
						<h6>Access List</h6>
						<AccessUsers report={reportData} />
					</div>

					{Object.keys(defaultReportData).map((key, index) => {
						return (
							<div className='d-flex flex-column my-4'>
								<h6>{capitalizeFirstLetter(key)}</h6>
								{key === 'date_delivered' ? (
									<DatePicker
										selected={reportData[key]}
										onChange={(date) =>
											updateReportData(key, date)
										}
										dateFormat='yyyy-MM-dd'
										className='form-control'
									/>
								) : (
									<TextEditor
										value={reportData[key]}
										onValueChange={(value) =>
											updateReportData(key, value)
										}
									/>
								)}
							</div>
						);
					})}

					{/* REPORT BUTTONS */}
					<div className='d-flex flex-row gap-2'>
						<button
							onClick={saveReport}
							className='btn btn-success my-3'
						>
							Save
						</button>
						<button
							onClick={exportReport}
							className='btn btn-secondary my-3'
						>
							Export
						</button>
					</div>
					<ToastContainer
						position='top-right'
						hideProgressBar={true}
						autoClose={2000}
						pauseOnHover
						theme='colored'
					/>
				</>
			)}
		</div>
	);
}

export default EditReport;