import React from 'react';
import { useState, useEffect } from 'react';
import {
	sendGetRequest,
	sendPutRequest
} from '../Utils/sendRequest';
import '../Style/UserForm.css';
import { Form } from 'react-bootstrap';
import { notify } from '../Utils/notify';

function UpdateUserForm({ userId }) {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [email, setEmail] = useState('');
	const [role, setRole] = useState('');

	const roles = ['PlatformAdmin', 'User'];
	const [userData, setUserData] = useState([]);

	async function getUserData() {
		const response = await sendGetRequest(`/admin/users/${userId}`);
		setUserData(response.data);
	}

	useEffect(() => {
		getUserData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setUsername(userData.username);
		setEmail(userData.email);
		setRole(userData.role);
		// setPassword(userData.password);
	}, [userData]);

	async function updateUser(e) {
		e.preventDefault();
		// eslint-disable-next-line no-unused-vars
		const userDataSent = {
			username: username,
			password: password,
			email: email,
			role: role,
		};

		const requestBody = {
			update_data: {
				username: username,
			},
		};
		const response = await sendPutRequest(`/admin/users/${userId}`, requestBody);

		if (response.status === 200) {
			notify('success', response.data.message);
			window.location.reload();
		} else {
			notify('error', response.data.message);
		}
	}

	return (
		<form onSubmit={updateUser} className='user-form'>
			<div className='d-flex flex-column gap-2'>
				<label>Username</label>

				<Form.Control
					type='text'
					placeholder='Username'
					name='username'
					className='form-control py-2'
					value={username}
					onChange={(e) => setUsername(e.target.value)}
				/>
				<label>Password</label>
				<Form.Control
					type='password'
					placeholder='Password'
					name='password'
					className='form-control py-2'
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<label>Email</label>
				<Form.Control
					type='text'
					placeholder='email'
					name='email'
					className='form-control py-2'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<label>Role</label>

				<Form.Control
					as='select'
					value={role}
					onChange={(event) => {
						const selectedValue = event.target.value;
						setRole(selectedValue);
					}}
				>
					{roles.map((option, index) => {
						return (
							<option key={index} value={option}>
								{option}
							</option>
						);
					})}
				</Form.Control>
			</div>

			<button type='submit' name='login' className='theme-btn'>
				Update
			</button>
		</form>
	);
}

export default UpdateUserForm;
