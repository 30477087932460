// react
import React from 'react';
import { Navigate } from 'react-router-dom';

//components
import SystemKeyAuth from '../../Components/AuthComponents/SystemKeyAuth';

//style
import '../../Style/AdminPanel.css';

function AdminPanel() {
	const systemKey = sessionStorage.getItem('system_key');

	return (
		<>
			{!systemKey && <SystemKeyAuth />}
			{systemKey && <Navigate to='/admin' />}
		</>
	);
}

export default AdminPanel;
