// React
import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

//utils
import { getUserRole } from '../Utils/getUserRole';
import { sendPostRequest } from '../Utils/sendRequest';

// style
import '../Style/SideBar.css';

const Sidebar = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [userRole, setUserRole] = useState('');
	const [showAdminSubMenu, setShowAdminSubMenu] = useState(false);

	async function logout() {
		await sendPostRequest('/auth/logout');
		sessionStorage.clear();
		navigate('/login');
	}

	useEffect(() => {
		setUserRole(getUserRole());
	}, []);

	const toggleAdminSubMenu = () => {
		setShowAdminSubMenu(!showAdminSubMenu);
	};

	return (
		<div className='sidebar'>
			<div className='sidebar-logo' onClick={() => navigate('/')}>
				<b>GWXR Platform</b> <br></br> <i>report writing made easy.</i>
			</div>
			<hr />
			<ul>
				<li
					className={`d-flex flex-row gap-2 ${
						location.pathname.startsWith('/user') ? 'active' : ''
					}`}
					onClick={() => navigate('/user/reports')}
				>
					<i className='bi bi-journal gap-3'></i>
					<div>Reports</div>
				</li>
				{userRole === 'PlatformAdmin' && (
					<>
						<li
							className={`d-flex flex-row gap-2
								${location.pathname.startsWith('/admin') ? 'active' : ''}`}
							onClick={() => toggleAdminSubMenu()}
						>
							<i className='bi bi-person-circle'></i>
							<div>
								Admin Panel
								{!showAdminSubMenu ? (
									<i className='bi bi-caret-right-fill options-arrow'></i>
								) : (
									<i className='bi bi-caret-down-fill options-arrow'></i>
								)}
							</div>
						</li>
						{showAdminSubMenu && (
							<ul>
								<li
									className={
										location.pathname.startsWith(
											'/admin/users'
										)
											? 'active admin-options'
											: 'admin-options'
									}
									onClick={() => navigate('/admin/users')}
								>
									<i className="bi bi-people"> </i>
									Users
								</li>
								<li
									className={
										location.pathname.startsWith(
											'/admin/organizations'
										)
											? 'active admin-options'
											: 'admin-options'
									}
									onClick={() => navigate('/admin/organizations')}
								>
									<i className="bi bi-building"> </i>
									Organizations
								</li>
								<li
									className={
										location.pathname.startsWith(
											'/admin/reports'
										)
											? 'active admin-options'
											: 'admin-options'
									}
									onClick={() => navigate('/admin/reports')}
								>
									<i className='bi bi-journal'> </i>
									Reports
								</li>
							</ul>
						)}
					</>
				)}

				<li
					className={`d-flex flex-row gap-2 ${
						location.pathname === '/settings' ? 'active' : ''
					}`}
					onClick={() => navigate('/settings')}
				>
					<i className='bi bi-gear'></i>
					Settings
				</li>
				<hr />

				<li className='d-flex flex-row gap-2' onClick={logout}>
					<i className='bi bi-box-arrow-left'></i>
					Log out
				</li>
			</ul>
		</div>
	);
};

export default Sidebar;
