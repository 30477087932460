import React from 'react';
import HandleUsers from '../../Components/HandleUsers';
import CustomModal from '../../Components/CustomModal';
import NewUserForm from '../../Components/NewUserForm';
import NavigationBar from '../../Components/NavigationBar';

function UserManagement() {
	const navItems = [
		{
			title: 'User Management',
			url: '',
		},
	];
	return (
		<>
			
			<div className='navigation-bar'>
				<NavigationBar items={navItems} />
			</div>
			<HandleUsers />
			<CustomModal
				className={'theme-btn mt-2'}
				variant={'success'}
				buttonLabel={
					<div className='d-flex gap-2'>
						<div>Create</div>
						<i className='bi bi-plus-circle'></i>
					</div>
				}
				title={'Create New User'}
				body={<NewUserForm />}
				full={false}
			/>
		</>
	);
}

export default UserManagement;
