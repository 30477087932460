import axios from 'axios';

// Get the baseURL based on the environment
const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost' : 'https://api.gwrx.eu';

const axiosInstance = axios.create({
	baseURL: `${baseURL}/api/v1`,
	headers: {
		'Content-Type': 'application/json',
	},
});

axiosInstance.interceptors.request.use(
	function (config) {
		const token = sessionStorage?.getItem('access_token');
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		
		if (config.url.endsWith('export')) {
			config.responseType = 'blob';
		}

		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

export default axiosInstance;
