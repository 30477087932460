import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { sendPatchRequest } from '../../Utils/sendRequest';
import { sanitizeData } from '../../Utils/sanitzeData';

import NavigationBar from '../../Components/NavigationBar';
import SkeletonLoader from '../../Components/SkeletonLoader';

function UserFindingsDetails() {
	const [finding, setFinding] = useState([]);
	const params = useParams();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	const navigationItems = [
		{
			title: 'Reports',
			url: `/user/reports`,
		},
		{
			title: 'Report Details',
			url: `/user/reports/${params.reportId}`,
		},
		{
			title: 'Finding Details',
			url: ``,
		},
	];

	useEffect(() => {
		getFindingsData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getFindingsData() {
		var systemKey = sessionStorage.getItem('private_key');
		var requestBody = {
			private_key: systemKey,
		};
		var response = await sendPatchRequest(
			`/reports/${params.reportId}/findings/${params.findingId}`,
			requestBody
		);
		console.log(response);
		setFinding(response.data);
		setLoading(false);
	}

	function editFinding() {
		navigate(`/user/reports/${params.reportId}/${params.findingId}/edit`);
	}

	const renderSection = (label, data, isHTML = false) => {
		if (sanitizeData(data) !== 'N/A') {
			return (
				<>
					<div className='d-flex flex-column'>
						<h6>{label}:</h6>
						{isHTML ? (
							<div dangerouslySetInnerHTML={{ __html: data }} />
						) : (
							<div>{sanitizeData(data)}</div>
						)}
					</div>
					<hr />
				</>
			);
		}
		return null;
	};

	return (
		<>
			{loading && (
				<SkeletonLoader height={75} count={10} color={'#18283b'} />
			)}
			{!loading && (
				<div className='report-details-wrapper'>
					<div className='navigation-bar'>
						<NavigationBar items={navigationItems} />
					</div>

					<div className='d-flex flex-column'>
						<h6>Finding Id:</h6>
						<div>{params.findingId}</div>
					</div>
					<hr />

					{renderSection('Finding Title', finding.title, true)}
					{renderSection('Severity', finding.severity)}
					{renderSection('CVSS Score', finding.cvss_score)}
					{renderSection('Description', finding.description, true)}
					{renderSection('Attack Scenario', finding.attack_scenario)}
					{renderSection('Attack Chain', finding.attack_chain)}
					{renderSection('Location', finding.location)}
					{renderSection('Impact', finding.impact)}
					{renderSection('Recommendation', finding.recommendation)}
					{renderSection('References', finding.references)}

					<button
						style={{ width: '75px' }}
						className='btn btn-secondary my-3 d-flex gap-2'
						onClick={editFinding}
					>
						<div>Edit</div>
						<i className='bi bi-pencil'></i>
					</button>
				</div>
			)}
		</>
	);
}

export default UserFindingsDetails;
