// react
import React from 'react';
import { Navigate } from 'react-router-dom';

//components
import PrivateKeyAuth from '../../Components/AuthComponents/PrivateKeyAuth';

//style
import '../../Style/AdminPanel.css';

function UserPanel() {
	const privateKey = sessionStorage.getItem('private_key');

	return (
		<>
			{!privateKey && <PrivateKeyAuth />}
			{privateKey && <Navigate to='/user' />}
		</>
	);
}

export default UserPanel;
