/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';

// Utils
import { sendPostRequest, sendGetRequest } from '../Utils/sendRequest';

// Style
import '../Style/Table.css';

function AccessUsers({ report }) {
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const limit = 10;

    async function fetchUsers() {
        var response = await sendGetRequest('/admin/users?page=1&per_page=5');
        const usersWithAccess = await Promise.all(
            response.data.users.map(async (user) => {
                const accessResponse = await sendPostRequest(
                    `/admin/reports/${report.id}/check-access`,
                    {
                        "user_id": user.id,
                        "access_type": "*",
                        "access_location": "*"
                    }
                );
                return { ...user, hasAccess: accessResponse.data.has_access };
            })
        );
        setUsers(usersWithAccess);
    }

    function searchUsers() {
        const usersFound = users.filter((user) => {
            const regex = new RegExp(searchTerm, 'i');
            return regex.test(user.username) || regex.test(user.email);
        });
        setFilteredItems(usersFound);
    }

    //#region GET DATA
    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        setFilteredItems(users);
    }, [users]);

    useEffect(() => {
        searchUsers();
    }, [searchTerm]);
    //#endregion

    //#region  SET ACCESS TO USERS
    async function updateAccessArray(user, hasAccess) {
        const scope = hasAccess ? 'grant' : 'revoke';
        await sendPostRequest(`/admin/reports/${report.id}/modify-access`, {
            "scope": scope,
            "user_id": user.id,
            "access_type": "*",
            "access_location": "*",
            "private_key": sessionStorage.getItem('system_key')
        });

        // Update the local state to reflect the change
        setUsers((prevUsers) =>
            prevUsers.map((u) =>
                u.id === user.id ? { ...u, hasAccess: hasAccess } : u
            )
        );
    }
    //#endregion

    return (
        <>
            <FormControl
                type='text'
                placeholder='Search username or email...'
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                className='mb-3'
            />

            <table className='styled-table'>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Access?</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredItems &&
                        filteredItems.slice(0, limit).map((user, index) => {
                            return (
                                <tr key={index}>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>{user.role}</td>
                                    <td>
                                        <Checkbox
                                            access={user.hasAccess}
                                            user={user}
                                            updateAccessArray={
                                                updateAccessArray
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    {filteredItems.length === 0 && (
                        <tr>
                            <td colSpan="4">User not found</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}

export default AccessUsers;

const Checkbox = ({ access, user, updateAccessArray }) => {
    const [checked, setChecked] = useState(access);

    const handleCheckboxChange = () => {
        const newCheckedState = !checked;
        setChecked(newCheckedState);
        updateAccessArray(user, newCheckedState);
    };

    useEffect(() => {
        setChecked(access);
    }, [access]);

    return (
        <div>
            <label>
                <input
                    type='checkbox'
                    checked={checked}
                    onChange={handleCheckboxChange}
                />
            </label>
        </div>
    );
};
