/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FormControl, Pagination } from 'react-bootstrap';

// Utils
import { sendGetRequest } from '../Utils/sendRequest';

// Style
import '../Style/Table.css';
import UserManagementModals from './UserManagementModals';

function HandleUsers() {
	const [users, setUsers] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const limit = 4;

	async function fetchUsers(page = 1) {
		// Assuming sendPostRequest takes a URL and an options object
		const response = await sendGetRequest(`/admin/users?page=${page}&per_page=${limit}`, {});
		setUsers(response.data.users);
		setTotalPages(response.data.pagination.total_pages);  // Assuming the backend returns the total pages
	}

	function searchUsers() {
		setCurrentPage(1);
		fetchUsers(1); // Reset to first page on search
	}

	useEffect(() => {
		fetchUsers(currentPage); // Fetch users for the current page
	}, [currentPage]);

	useEffect(() => {
		searchUsers();
	}, [searchTerm]);

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	return (
		<>
			<div>
				<FormControl
					type='text'
					placeholder='Search username or email...'
					onChange={(e) => setSearchTerm(e.target.value)}
					value={searchTerm}
					className='mb-3'
				/>
			</div>
			<table className='styled-table'>
				<thead>
					<tr>
						<th>Username</th>
						<th>Email</th>
						<th>Role</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{users.length > 0 ? (
						users.map((user, index) => (
							<tr key={index}>
								<td>{user.username}</td>
								<td>{user.email}</td>
								<td>{user.role}</td>
								<td width='150px'>
									<UserManagementModals
										userIdentifier={user.username}
										userId={user.id}
									/>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="4">User not found</td>
						</tr>
					)}
				</tbody>
			</table>

			{totalPages > 1 && (
				<div className="d-flex justify-content-center mt-3">
					<Pagination>
						<Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
						<Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
						{[...Array(totalPages)].map((_, index) => (
							<Pagination.Item
								key={index}
								active={index + 1 === currentPage}
								onClick={() => handlePageChange(index + 1)}
							>
								{index + 1}
							</Pagination.Item>
						))}
						<Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
						<Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
					</Pagination>
				</div>
			)}
		</>
	);
}

export default HandleUsers;
