import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sendPostRequest } from '../../Utils/sendRequest';
import NavigationBar from '../../Components/NavigationBar';

function UserDetails() {
	const { userId } = useParams();
	const [userData, setUserData] = useState({});

	const navItems = [
		{
			title: 'User Management',
			url: '/admin/users',
		},
		{
			title: 'User Details',
			url: '',
		},
	];

	async function getUserData() {
		const userData = {
			user_identifier: userId,
		};
		const response = await sendPostRequest('/admin/users', userData);
		console.log(response);

		setUserData(response.data);
	}

	useEffect(() => {
		getUserData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const displayData = () => {
		return Object.entries(userData).map(([key, value]) => (
			<div key={key} className='d-flex flex-row gap-2'>
				<strong>
					{key.replace(/_/g, ' ').charAt(0).toUpperCase() +
						key.replace(/_/g, ' ').slice(1)}
				</strong>
				<div>{value}</div>
			</div>
		));
	};

	return (
		<>
			<div className='navigation-bar'>
				<NavigationBar items={navItems} />
			</div>
			<div className='d-flex flex-column gap-2'>{displayData()}</div>
		</>
	);
}

export default UserDetails;
