import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Pagination } from 'react-bootstrap';

import { sendDeleteRequest, sendPatchRequest } from '../../Utils/sendRequest';

// components
import CustomModal from '../CustomModal';

// Styling
import '../../Style/Table.css';
import { notify } from '../../Utils/notify';
// notification
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const buttonLabel = (
    <div className='d-flex flex-row gap-2'>
        <div>Create</div>
        <i className='bi bi-plus-circle-dotted'></i>
    </div>
);

function UserFindings({ reportId, isAdmin }) {
    const navigate = useNavigate();
    const params = useParams();

    const privateKey = isAdmin ? sessionStorage.getItem('system_key') : sessionStorage.getItem('private_key');
    const [findings, setFindings] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        itemsPerPage: 4,
    });
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [error, setError] = useState('');
    const baseUrl = isAdmin ? '/admin/reports' : '/reports';

    async function getFindings(page = 1) {
        setLoading(true);
        const params = {
            private_key: privateKey,
            page: page,
            per_page: pagination.itemsPerPage,
        };

        const response = await sendPatchRequest(`${baseUrl}/${reportId}/findings?page=${params.page}&per_page=${params.per_page}`, { private_key: params.private_key });

        if (response?.status === 200) {
            const fetchedFindings = Array.isArray(response?.data.findings) ? response.data.findings : [];
            
            if (fetchedFindings.length === 0) {
                setError("No reports found.");
                setFindings([]);
            } else {
                setFindings(fetchedFindings);
                setPagination({
                    ...pagination,
                    currentPage: response.data.pagination.current_page,
                    totalPages: response.data.pagination.total_pages,
                });
            }

            setLoading(false);
        } else {
            setError(response.data.message || "An error occurred.");
            setLoading(false);
        }
    }

    useEffect(() => {
        if (privateKey) getFindings(pagination.currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privateKey, pagination.currentPage]);

    function viewFinding(index) {
        if (isAdmin) {
            navigate(`/admin/reports/${reportId}/${findings[index].id}`);
        } else {
            navigate(`/user/reports/${reportId}/${findings[index].id}`);
        }
    }

    function editFinding(index) {
        if (isAdmin) {
            navigate(`/admin/reports/${reportId}/${findings[index].id}/edit`);
        } else {
            navigate(`/user/reports/${reportId}/${findings[index].id}/edit`);
        }
    }

    async function deleteFinding(index) {
        const response = await sendDeleteRequest(
            `${baseUrl}/${params.reportId}/findings/${findings[index].id}`,
            {}
        );
        console.log('RESPONSE', response);
        if (response.status === 200) {
            notify('success', response.data.message);
            setTimeout(() => window.location.reload(), 1000);
        }
    }

    return (
        <div>
            <table className='styled-table'>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Severity</th>
                        <th>CVSS Score</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {findings &&
                        findings.map((finding, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {finding.title.replace(
                                            /<[^>]*>?/gm,
                                            ''
                                        )}
                                    </td>
                                    <td>{finding.severity}</td>
                                    <td>{finding['cvss_score']}</td>
                                    <td className='d-flex flex-row gap-1'>
                                        <button
                                            onClick={() => viewFinding(index)}
                                            className='btn btn-sm btn-success d-flex w gap-1'
                                        >
                                            <div>View</div>
                                            <i className='bi bi-eye-fill'></i>
                                        </button>
                                        <button
                                            onClick={() => editFinding(index)}
                                            className='btn btn-sm btn-secondary d-flex gap-1'
                                        >
                                            <div>Edit</div>
                                            <i className='bi bi-pencil'></i>
                                        </button>
                                        <CustomModal
                                            buttonLabel={
                                                <div className='d-flex flex-row gap-2'>
                                                    <div>Delete</div>
                                                    <i className='bi bi-trash'></i>
                                                </div>
                                            }
                                            className={
                                                'btn btn-danger btn-sm d-flex gap-1'
                                            }
                                            title='Are you sure you want to proceed?'
                                            functionality={() =>
                                                deleteFinding(index)
                                            }
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    {findings.length === 0 && (
                        <tr>
                            <td>Not findings yet</td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div className='pagination-controls'>
            {loading ? (
				<></>
                ) : (
                <Pagination>
                    <Pagination.Prev
                        onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}
                        disabled={pagination.currentPage === 1}
                    />
                    {[...Array(pagination.totalPages)].map((_, i) => (
                        <Pagination.Item
                            key={i + 1}
                            active={i + 1 === pagination.currentPage}
                            onClick={() => setPagination({ ...pagination, currentPage: i + 1 })}
                        >
                            {i + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}
                        disabled={pagination.currentPage === pagination.totalPages}
                    />
                </Pagination>
                )}
            </div>
            <button
                className='theme-btn mt-2'
                onClick={() =>
                    navigate(`/user/reports/${params.reportId}/createFinding`)
                }
            >
                {buttonLabel}
            </button>
            <ToastContainer
                position='top-right'
                hideProgressBar={false}
                autoClose={1000}
                pauseOnHover
                theme='colored'
            />
        </div>
    );
}

export default UserFindings;