/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';

// Utills
import { sendGetRequest } from '../Utils/sendRequest';

//style
import '../Style/Table.css';
import OrganizationManagementModals from './OrganizationManagementModals';

function HandleOrganizations() {
	const [organizations, setOrganizations] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [filteredItems, setFilteredItems] = useState([]);
	const limit = 10;

	async function fetchOrganizations() {
		var response = await sendGetRequest('/admin/organizations');
		setOrganizations(response.data.organizations);
	}

	function searchOrganizations() {
		const organizationsFound = organizations.filter((organization, index) => {
			const regex = new RegExp(searchTerm, 'i');
			return regex.test(organization.name) || regex.test(organization.email);
		});
		setFilteredItems(organizationsFound);
	}

	useEffect(() => {
		fetchOrganizations();
	}, []);

	useEffect(() => {
		setFilteredItems(organizations);
	}, [organizations]);

	useEffect(() => {
		searchOrganizations();
	}, [searchTerm]);

	return (
		<>
			<div>
				<FormControl
					type='text'
					placeholder='Search name...'
					onChange={(e) => setSearchTerm(e.target.value)}
					value={searchTerm}
					className='mb-3'
				/>
			</div>
			<table className='styled-table'>
				<thead>
					<tr>
						<th>Name</th>
						<th>Email</th>
						<th>Phone Number</th>
						<th>AFM</th>
						<th>Address</th>
						<th>Field</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{filteredItems &&
						filteredItems.slice(0, limit).map((organization, index) => {
							return (
								<tr key={index}>
									<td>{organization.name}</td>
									<td>{organization.email}</td>
									<td>{organization.phone_number}</td>
									<td>{organization.afm}</td>
									<td>{organization.address}, {organization.city}</td>
									<td>{organization.field}</td>
									{/* <td>
										<Checkbox />
									</td> */}
									<td width='150px'>
										<OrganizationManagementModals
											organizationIdentifier={organization.name}
											organizationId={organization.id}
										/>
									</td>
								</tr>
							);
						})}
					{filteredItems.length === 0 && (
						<tr>
							<td>Organization not found</td>
						</tr>
					)}
				</tbody>
			</table>
		</>
	);
}

export default HandleOrganizations;