import axiosInstance from './axiosConfig';

export async function sendPostRequest(route, params) {
	return await axiosInstance
		.post(route, params)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			if (error.response) {
				console.error('Error status:', error.response.status); // HTTP status code
				console.error('Error data:', error.response.data); // Response payload
				return error.response;
			} else {
				console.error('Error:', error.message);
			}
		});
}

export async function sendPatchRequest(route, params) {
	return await axiosInstance
		.patch(route, params)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			if (error.response) {
				console.error('Error status:', error.response.status); // HTTP status code
				console.error('Error data:', error.response.data); // Response payload
				return error.response;
			} else {
				console.error('Error:', error.message);
			}
		});
}

export async function sendPutRequest(route, params) {
	// console.log(params);
	return await axiosInstance
		.put(route, params)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			if (error.response) {
				console.error('Error status:', error.response.status); // HTTP status code
				console.error('Error data:', error.response.data); // Response payload
				return error.response;
			} else {
				console.error('Error:', error.message);
			}
		});
}

export async function sendDeleteRequest(route, params) {
	return await axiosInstance
		.delete(route, { data: params })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			if (error.response) {
				console.error('Error status:', error.response.status); // HTTP status code
				console.error('Error data:', error.response.data); // Response payload
				return error.response;
			} else {
				console.error('Error:', error.message);
			}
		});
}

export async function sendGetRequest(route) {
	return await axiosInstance
		.get(route)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			if (error.response) {
				console.error('Error status:', error.response.status); // HTTP status code
				console.error('Error data:', error.response.data); // Response payload
				return error.response;
			} else {
				console.error('Error:', error.message);
			}
		});
}
