import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'react-bootstrap'; // Import Pagination component
import { sendPatchRequest, sendPostRequest } from '../../Utils/sendRequest';
import Report from '../../Components/Report';
import SkeletonLoader from '../../Components/SkeletonLoader';
import { Dropdown, Button, ButtonGroup } from 'react-bootstrap';
import NavigationBar from '../../Components/NavigationBar';
import '../../Style/Reports.css';
import { notify } from '../../Utils/notify';


function UserReports() {
    const privateKey = sessionStorage.getItem('private_key');
    const [reports, setReports] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        itemsPerPage: 4,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const navItems = [
        { title: 'Reports', url: '' },
    ];

    async function getReports(page = 1) {
        setLoading(true);
        const params = {
            private_key: privateKey,
            page: page,
            per_page: pagination.itemsPerPage,
        };

        const response = await sendPatchRequest(`/reports/?page=${params.page}&per_page=${params.per_page}`, { private_key: params.private_key });

        if (response?.status === 200) {
            const fetchedReports = Array.isArray(response?.data.reports) ? response.data.reports : [];
            
            if (fetchedReports.length === 0) {
                setError("No reports found.");
                setReports([]);
            } else {
                setReports(fetchedReports);
                setPagination({
                    ...pagination,
                    currentPage: response.data.pagination.current_page,
                    totalPages: response.data.pagination.total_pages,
                });
            }

            setLoading(false);
        } else {
            setError(response.data.message || "An error occurred.");
            setLoading(false);
        }
    }

    useEffect(() => {
        if (privateKey) getReports(pagination.currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privateKey, pagination.currentPage]);

    return (
        <div className='page-container'>
            <div className='content-wrapper'>
                <div className='d-flex flex-row justify-content-between'>
                    <div className='navigation-bar'>
                        <NavigationBar items={navItems} />
                    </div>
                    <CreateReport />
                </div>
                {privateKey && loading && (
                    <div className='reports-wrapper'>
                        <SkeletonLoader height={310} count={2} color={'#C0C0C0'} />
                        <SkeletonLoader height={310} count={2} color={'#C0C0C0'} />
                    </div>
                )}
                {privateKey && reports.length > 0 && !loading && (
                    <div className='reports-wrapper'>
                        {reports.map((report, index) => (
                            <Report key={index} report={report} />
                        ))}
                    </div>
                )}

                {privateKey && error && !loading && (
                    <div className='error-message'>
                        {error}
                    </div>
                )}
            </div>

            {/* Pagination */}
            <div className='pagination-controls'>
            {loading ? (
				<SkeletonLoader height={40} count={1} color={'#C0C0C0'} />
                ) : (
                <Pagination>
                    <Pagination.Prev
                        onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage - 1 })}
                        disabled={pagination.currentPage === 1}
                    />
                    {[...Array(pagination.totalPages)].map((_, i) => (
                        <Pagination.Item
                            key={i + 1}
                            active={i + 1 === pagination.currentPage}
                            onClick={() => setPagination({ ...pagination, currentPage: i + 1 })}
                        >
                            {i + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => setPagination({ ...pagination, currentPage: pagination.currentPage + 1 })}
                        disabled={pagination.currentPage === pagination.totalPages}
                    />
                </Pagination>
                )}
            </div>
        </div>
    );
}

export default UserReports;

function CreateReport() {
	const navigate = useNavigate();
	const fileInputRef = useRef(null);

	const handleCreateClick = () => {
		navigate('/reports/create');
	};

	const handleImportClick = () => {
		fileInputRef.current.click(); // Trigger the file input
	};

	const handleFileUpload = async (event) => {
		const file = event.target.files[0];
		if (file) {
			try {
				const jsonData = await file.text();
				const parsedData = JSON.parse(jsonData);
                const private_key = sessionStorage.getItem('private_key')
                const requestBody = {
                    private_key: private_key,
                    report: parsedData.report
                };
				const response = await sendPostRequest('/reports/import', requestBody);
				
				if (response.status === 201) {
					notify('success', 'Report imported successfully')
					setTimeout(() => {
						navigate(`/user/reports/${response.data.report.id}`);
					}, 1000);
                } else {
                    notify('error', response.data.message || 'Failed to import report.')
				}
			} catch (error) {
				notify('error', 'Failed to import report.')
			}
		}
	};

	return (
		<div>
            <Dropdown data-bs-theme="dark" as={ButtonGroup}>
                <Button variant="success" className='mb-3' onClick={handleCreateClick}>
                    Create
                </Button>

                <Dropdown.Toggle split className='mb-3' variant="success" id="dropdown-split-basic" />

                <Dropdown.Menu>
                    <Dropdown.Item onClick={handleImportClick}>
                        <div className='d-flex flex-row gap-2 align-items-center'>
                            <span>Import</span>
                            <i className='bi bi-file-earmark-arrow-up'></i>
                        </div>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
			<input
				type="file"
				accept="application/json"
				ref={fileInputRef}
				onChange={handleFileUpload}
				style={{ display: 'none' }}
			/>
		</div>
	);
}
