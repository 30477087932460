import React from 'react';
import { useState } from 'react';
import { sendPostRequest } from '../Utils/sendRequest';
import '../Style/UserForm.css';
import { Form } from 'react-bootstrap';

import { notify } from '../Utils/notify';
import { Button } from 'react-bootstrap';

// notification
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NewOrganizationForm() {

    const [organizationData, setOrganizationData] = useState({});
    const [disableBtn, setDisableBtn] = useState(false);

    async function createNewOrganization(e) {
        e.preventDefault();

        const response = await sendPostRequest('/admin/organizations', organizationData);
        console.log(response);

        if (response.status === 201) {
            setDisableBtn(true);
            notify('success', response.data.message);
            setTimeout(() => window.location.reload(), 2000);
        } else {
            notify('error', response.data.message);
        }
    }

    const organizationFields = [
        { name: 'name', placeholder: 'Organization name', value: "" },
        { name: 'city', placeholder: 'City', value: "" },
        { name: 'email', placeholder: 'Email', value: "" },
        { name: 'address', placeholder: 'Address', value: "" },
        { name: 'postal_code', placeholder: 'Postal code', value: "" },
        { name: 'afm', placeholder: 'AFM', value: "" },
        { name: 'doy', placeholder: 'DOY', value: "" },
        { name: 'phone_number', placeholder: 'Phone number', value: "" },
        { name: 'field', placeholder: 'Field', value: "" }
    ];
    
    return (
        <>
            <form onSubmit={createNewOrganization} className='organization-form'>
                <div className='d-flex flex-column flex-md-row flex-wrap gap-2'>
                {organizationFields.map((field, index) => (
                    <div key={index}>
                        <label>{field.placeholder}</label>
                        <Form.Control
                            type='text'
                            placeholder={field.placeholder}
                            name={field.name}
                            className='form-control py-2'
                            value={organizationData[field.name] || ''}
                            onChange={(e) => setOrganizationData({ ...organizationData, [field.name]: e.target.value })}
                        />
                    </div>
                ))}
                </div>

                <Button variant="success" type='submit' name='login' className='mb-3' disabled={disableBtn}>
                    Create
                </Button>
            </form>
            <ToastContainer
                position='top-right'
                hideProgressBar={false}
                autoClose={2000}
                pauseOnHover
                theme='colored'
            />
        </>
    );
}

export default NewOrganizationForm;