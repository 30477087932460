import React from 'react';
import { useState } from 'react';
import { sendPostRequest } from '../../Utils/sendRequest';
import { useNavigate } from 'react-router-dom';

//style
import '../../Style/AuthComponents.css';

function Register() {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [error, setError] = useState('');
	const navigate = useNavigate();

	const handleRegister = async (event) => {
		event.preventDefault();

		const registerData = {
			name: event.target[0].value,
			pwd: event.target[1].value,
		};
		var response = sendPostRequest('/auth/register', registerData);
		console.log(response);
	};

	return (
		<div className='login-wrapper'>
			<form onSubmit={handleRegister} className='login-form'>
				<div className='logo'>GWRX Group Logo</div>
				<div>
					<input
						type='text'
						placeholder='Username'
						name='username'
						className='form-control py-2'
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
					<input
						type='password'
						placeholder='Password'
						name='password'
						className='form-control py-2 mb-5 mt-2'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>

					<button type='submit' name='login' className='login-btn'>
						Register
					</button>
					<div className='footer'>
						<span onClick={() => navigate('/login')}>
							Have an account? Sign in
						</span>
					</div>
				</div>
				{error !== '' && (
					<div className='alert alert-danger' role='alert'>
						Wrong Username or pwd
					</div>
				)}
			</form>
		</div>
	);
}

export default Register;

// {
// 	/* <div>
// 			<form onSubmit={handleRegister}>
// 				<div className='form-group pb-3'>
// 					<input
// 						type='text'
// 						placeholder='Username'
// 						name='username'
// 						className='form-control rounded-pill w-75 mx-auto'
// 					/>
// 				</div>
// 				<div className='form-group pb-3'>
// 					<input
// 						type='password'
// 						placeholder='Password'
// 						name='password'
// 						className='form-control rounded-pill w-75 mx-auto'
// 					/>
// 				</div>
// 				<button type='submit' name='login'>
// 					Log in
// 				</button>
// 			</form>
// 		</div> */
// }
