import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Components
import UserFindings from '../../../Components/Findings/UserFindings';
import NavigationBar from '../../../Components/NavigationBar';
import SkeletonLoader from '../../../Components/SkeletonLoader';
import CustomModal from '../../../Components/CustomModal';

// Utils
import {
	sendDeleteRequest,
	sendPatchRequest,
} from '../../../Utils/sendRequest';
import { sanitizeData } from '../../../Utils/sanitzeData';

// Style
import '../../../Style/Reports.css';
import '../../../Style/Navigation.css';

function ReportDetails() {
	const params = useParams();
	const navigate = useNavigate();
	const [reportData, setReportData] = useState({});
	const [loading, setLoading] = useState(true);

	const navigationItems = [
		{
			title: 'Reports',
			url: '/admin/reports',
		},
		{
			title: 'Report Details',
			url: ``,
		},
	];

	useEffect(() => {
		getReportData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getReportData() {
		var systemKey = sessionStorage.getItem('system_key');
		var requestBody = {
			private_key: systemKey,
		};

		var reportsResponse = await sendPatchRequest(
			`/admin/reports/${params.reportId}`,
			requestBody
		);

		setReportData(reportsResponse.data);
		setLoading(false);
	}

	function editReport() {
		navigate(`/admin/reports/${params.reportId}/edit`);
	}

	async function exportReport() {
		const requestBody = {
			private_key: sessionStorage.getItem('system_key'),
			template: 'basic',
			export_format: 'pdf',
		};

		var token = sessionStorage.getItem('access_token');

		const response = await axios.post(
			`/admin/reports/${reportData.id}/export`,
			requestBody,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
				responseType: 'blob',
			}
		);

		const blob = new Blob([response.data], { type: 'application/pdf' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'report.pdf');
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	}

	async function deleteReport() {
		const response = await sendDeleteRequest(
			`/admin/reports/${params.reportId}`,
			{}
		);
		console.log(response);
	}

	const renderSection = (label, data, isHTML = false) => {
		if (sanitizeData(data) !== 'N/A') {
			return (
				<>
					<div className='d-flex flex-column'>
						<h6>{label}:</h6>
						{isHTML ? (
							<div dangerouslySetInnerHTML={{ __html: data }} />
						) : (
							<div>{sanitizeData(data)}</div>
						)}
					</div>
					<hr />
				</>
			);
		}
		return null;
	};

	return (
		<>
			{loading && (
				<SkeletonLoader height={65} count={10} color={'#C0C0C0'} />
			)}
			{!loading && (
				<div className='report-details-wrapper'>
					<div className='d-flex flex-row justify-content-between'>
						<div className='navigation-bar'>
							<NavigationBar items={navigationItems} />
						</div>
						<EditReport />
					</div>
					{renderSection('Report Id', reportData.id)}
					{renderSection('Report Title', reportData.title)}
					{renderSection('Report Type', reportData.assessment_type)}
					{renderSection('Date Delivered', reportData.date_delivered)}
					{renderSection('Creation Date', reportData.creation_date)}
					{renderSection('Overview', reportData.overview, true)}
					{renderSection('Description', reportData.description, true)}
					{renderSection('Exploit', reportData.exploit)}
					{renderSection('Summary', reportData.summary)}
					{renderSection(
						'Executive Summary',
						reportData.executive_summary
					)}
					{renderSection(
						'Summary of Findings',
						reportData.summary_of_findings
					)}
					<div className='d-flex flex-column'>
						<h6>Findings: </h6>
						<UserFindings
							reportId={params.reportId}
							isAdmin={true}
						/>
					</div>
					<hr />
					{renderSection('In Scope', reportData.in_scope)}
					{renderSection('Out of Scope', reportData.out_of_scope)}
					{renderSection('Methodology', reportData.methodology)}
					{renderSection(
						'Recommendations',
						reportData.recommendations
					)}
					{/* {renderSection('Overview', reportData.overview, true)} */}

					{/* REPORT BUTTONS */}
					<div className='d-flex flex-row gap-2'>
						<button
							className='btn btn-secondary mb-3'
							onClick={editReport}
						>
							{editButtonLabel}
						</button>
						<button
							onClick={exportReport}
							className='btn btn-success d-flex gap-2 mb-3'
						>
							<div>Export</div>
							<i className='bi bi-envelope-paper'></i>
						</button>
						<CustomModal
							buttonLabel={
								<div className='d-flex flex-row gap-2'>
									<div>Delete</div>
									<i className='bi bi-trash'></i>
								</div>
							}
							className={'btn btn-danger d-flex gap-2 mb-3 '}
							title='Are you sure?'
							functionality={deleteReport}
						/>
					</div>
				</div>
			)}
		</>
	);
}

export default ReportDetails;

const editButtonLabel = (
	<div className='d-flex flex-row justify-content-around gap-2'>
		<div>Edit</div>
		<i className='bi bi-pencil'></i>
	</div>
);

function EditReport() {
	const navigate = useNavigate();
	const params = useParams();

	function editReport() {
		navigate(`/admin/reports/${params.reportId}/edit`);
	}

	return (
		<button className='theme-btn mb-2' onClick={editReport}>
			{editButtonLabel}
		</button>
	);
}

/* <div className='reports-wrapper'>Loading</div> */
