import React, { useState, useEffect } from 'react';
import { sendGetRequest, sendPutRequest } from '../Utils/sendRequest';
import '../Style/UserForm.css';
import { Form } from 'react-bootstrap';
import { notify } from '../Utils/notify';

function UpdateOrganizationForm({ organizationId }) {
    const [organizationData, setOrganizationData] = useState({
        name: '',
        city: '',
        email: '',
        address: '',
        postal_code: '',
        afm: '',
        doy: '',
        phone_number: '',
        field: '',
    });

    async function getOrganizationData() {
        const response = await sendGetRequest(`/admin/organizations/${organizationId}`);

        if (response.status === 200) {
            notify('success', response.data.message);

            // Remove id from organization data
            delete response.data.id;

            // Set organization data
            setOrganizationData(response.data);
        } else {
            notify('error', response.data.message);
        }
        
    }

    useEffect(() => {
        getOrganizationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function updateOrganization(e) {
        e.preventDefault();
        const requestBody = {
            update_data: organizationData,
        };
        const response = await sendPutRequest(`/admin/organizations/${organizationId}`, requestBody);
        
        if (response.status === 200) {
            notify('success', response.data.message);
            window.location.reload();
        } else {
            notify('error', response.data.message);
        }
    }

    return (
        <form onSubmit={updateOrganization} className='organization-form'>
            <div className='d-flex flex-column flex-md-row flex-wrap gap-2'>
                {Object.keys(organizationData).map((key, index) => (
                    <div key={index}>
                        <label>{key.replace('_', ' ').toUpperCase()}</label>
                        <Form.Control
                            type='text'
                            placeholder={key.replace('_', ' ').toUpperCase()}
                            name={key}
                            className='form-control py-2'
                            value={organizationData[key]}
                            onChange={(e) =>
                                setOrganizationData({ ...organizationData, [key]: e.target.value })
                            }
                        />
                    </div>
                ))}
            </div>

            <button type='submit' name='login' className='theme-btn'>
                Update
            </button>
        </form>
    );
}

export default UpdateOrganizationForm;