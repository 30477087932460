import React, { useEffect } from 'react';
import { useState } from 'react';
import { sendGetRequest } from '../../Utils/sendRequest';
import { useNavigate } from 'react-router-dom';

//style
import '../../Style/AuthComponents.css';

// utils
import { notify } from '../../Utils/notify';

// notification
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PrivateKeyAuth() {
	const [privateKey, setPrivateKey] = useState('');
	const navigate = useNavigate();
	const [keyToClipboard, setKeyToClipboard] = useState('');

	async function getAuthed(e) {
		e.preventDefault();

		// Check if the private key is empty and over 128 characters
		if (privateKey === '') {
			notify('error', 'Private key cannot be empty.');
			return;
		}

		if (privateKey.length < 4096) {
			notify('error', 'Private key is too short.');
			return;
		}

		sessionStorage.setItem('private_key', privateKey);
		navigate('/user/reports');
	}

	async function generateKey() {
		console.log('Generating private key...');
		var response = await sendGetRequest('/reports/key');
		console.log('Response: ');
		console.log(response);
		if (response.status === 201) {
			console.log(response.data.private_key);
			setKeyToClipboard(response.data.private_key);
		} else if (response.status === 403) {
			notify('error', response.data.message);
		} else {
			console.log('huh');
			notify('error', 'Failed to generate private key.');
		}
	}

	const copyToClipboard = async (key) => {
		try {
			await navigator.clipboard.writeText(key);
			notify(
				'success',
				'Private key generated and copied to the clipboard. Make sure to save it and not lose it.'
			);
		} catch (err) {
			notify('error', 'Failed to copy to clipboard.');
			console.error('Failed to copy: ', err);
		}
	};

	useEffect(() => {
		if (keyToClipboard !== '') copyToClipboard(keyToClipboard);
	}, [keyToClipboard]);

	return (
		<>
			{/* <form className='access-form'> */}
			<div className='access-form'>
				<div className='d-flex flex-column justify-content-center'>
					<h2 className='mx-auto'>Access required</h2>
					<h6 className='mx-auto'>
						Please enter your private key required to decrypt
						reports.
					</h6>
				</div>
				<div>
					<input
						type='password'
						autoComplete='new-password'
						placeholder='Private key...'
						name='privateKey'
						className='form-control my-4 py-2'
						value={privateKey}
						onChange={(e) => setPrivateKey(e.target.value)}
					/>
					<button
						type='submit'
						name='access'
						className='access-btn'
						onClick={getAuthed}
					>
						Get Access
					</button>
					<button
						onClick={generateKey}
						name='access'
						className='access-btn'
						id='generate'
					>
						Generate
					</button>
				</div>
			</div>
			{/* </form> */}
			<ToastContainer
				position='top-right'
				hideProgressBar={true}
				pauseOnHover
				theme='colored'
			/>
		</>
	);
}

export default PrivateKeyAuth;
