import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export const IconWithTooltip = ({ icon, message }) => {
	return (
		<OverlayTrigger
			placement='top'
			overlay={<Tooltip id='tooltip-top'>{message}</Tooltip>}
		>
			<span className='icon-container'>{icon}</span>
		</OverlayTrigger>
	);
};
