import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

// Components
import TextEditor from '../../../Components/TextEditor';
import NavigationBar from '../../../Components/NavigationBar';
import SkeletonLoader from '../../../Components/SkeletonLoader';
// notification module
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// bootstrap form
import { Form } from 'react-bootstrap';

// Utils
import { sanitizeData } from '../../../Utils/sanitzeData';
import { sendPostRequest } from '../../../Utils/sendRequest';
import { notify } from '../../../Utils/notify';
import { capitalizeFirstLetter } from '../../../Utils/capitalizeFirst';
import { IconWithTooltip } from '../../../Utils/IconWithTooltip';

// Styling
import '../../../Style/Table.css';

function CreateFinding() {
	const params = useParams();
	const location = useLocation();
	const [findingData, setFindingData] = useState({
		title: '',
		description: '',
		severity: 'Info',
		cvss_score: 0,
		cwe: '',
		location: '',
		impact: '',
		recommendation: '',
		references: '',
		attack_chain: '',
		attack_scenario: '',
	});
	const [loading, setLoading] = useState(true);
	const severityOptions = ['Info', 'Low', 'Medium', 'High', 'Critical'];
	const [selectedOption, setSelectedOption] = useState(findingData.severity);

	const navigationItems = [
		{
			title: 'Reports',
			url: location.pathname.startsWith('/admin/reports')
				? '/admin/reports'
				: '/user/reports',
		},
		{
			title: 'Report Details',
			url: location.pathname.startsWith('/admin/reports')
				? `/admin/reports/${params.reportId}`
				: `/user/reports/${params.reportId}`,
		},
		{
			title: 'Create Finding',
			url: `/admin/${params.reportId}/createFinding`,
		},
	];

	const updateFindingData = (field, value) => {
		setFindingData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	useEffect(() => {
		setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveFinding = async () => {
		const key = location.pathname.startsWith('/admin')
			? sessionStorage.getItem('system_key')
			: sessionStorage.getItem('private_key');
		const requestBody = {
			private_key: key,
			finding: {
				title: findingData['title'],
				description: findingData['description'],
				severity: findingData['severity'],
				cvss_score: sanitizeData(findingData['cvss_score'], ''),
				cwe: findingData['cwe'],
				location: findingData['location'],
				impact: findingData['impact'],
				recommendation: findingData['recommendation'],
				references: findingData['references'],
				attack_chain: findingData['attack_chain'],
				attack_scenario: findingData['attack_scenario']
			}
		};
		console.log('REQUEST', requestBody);
		var response = null;
		if (location.pathname.startsWith('/admin')) {
			response = await sendPostRequest(
				`admin/reports/${params.reportId}/findings`,
				requestBody
			);
		} else {
			response = await sendPostRequest(
				`reports/${params.reportId}/findings`,
				requestBody
			);
		}

		console.log(response);
		if (response.status === 201) {
			notify('success', response.data.message);
			window.scrollTo({ top: 0, behavior: 'smooth' });
		} else {
			notify('error', "Finding wasn't created");
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	return (
		<div>
			<div className='navigation-bar'>
				<NavigationBar items={navigationItems} />
			</div>

			{loading && (
				<SkeletonLoader height={75} count={10} color={'#18283b'} />
			)}
			{!loading && (
				<>
					{Object.keys(findingData).map((key, index) => {
						if (key === 'cvss_score') {
							return (
								<div
									key={index}
									className='d-flex flex-column my-2'
								>
									<div className='d-flex flex-row gap-2'>
										<h6>{capitalizeFirstLetter(key)}</h6>
										<IconWithTooltip
											icon={
												<i className='bi bi-info-circle'></i>
											}
											message={
												'Accepted Values: 0.0 - 10.0'
											}
										/>
									</div>
									<TextEditor
										value={String(findingData[key])}
										onValueChange={(value) =>
											updateFindingData(key, value)
										}
									/>
								</div>
							);
						} else if (key === 'severity') {
							return (
								<div className='d-flex flex-row gap-3'>
									<h6 className='my-auto'>
										Select Severity:{' '}
									</h6>
									<Form.Control
										as='select'
										value={selectedOption}
										onChange={(event) => {
											const selectedValue =
												event.target.value;
											setSelectedOption(selectedValue);
											updateFindingData(
												'severity',
												selectedValue
											);
										}}
										style={{ width: '200px' }}
									>
										{severityOptions.map(
											(option, index) => {
												return (
													<option
														key={index}
														value={option}
													>
														{option}
													</option>
												);
											}
										)}
									</Form.Control>
								</div>
							);
						} else {
							return (
								<div
									key={index}
									className='d-flex flex-column my-2'
								>
									<h6>{capitalizeFirstLetter(key)}</h6>
									<TextEditor
										value={String(findingData[key])}
										onValueChange={(value) =>
											updateFindingData(key, value)
										}
									/>
								</div>
							);
						}
					})}

					<div className='d-flex flex-row gap-2'>
						<button
							onClick={saveFinding}
							className='btn btn-success my-3'
						>
							Save
						</button>
					</div>

					<ToastContainer
						position='top-right'
						hideProgressBar={true}
						autoClose={2000}
						pauseOnHover
						theme='colored'
					/>
				</>
			)}
		</div>
	);
}

export default CreateFinding;
