import React from 'react';
import CustomModal from './CustomModal';
import UpdateUserForm from './UpdateUserForm';
import { useNavigate } from 'react-router-dom';
import { sendDeleteRequest } from '../Utils/sendRequest';

import { notify } from '../Utils/notify';
// notification
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UserManagementModals({ userId }) {
	const navigate = useNavigate();

	async function deleteUser() {
		const response = await sendDeleteRequest(`/admin/users/${userId}`);

		if (response.status === 200) {
			notify('success', response.data.message);
			setTimeout(() => window.location.reload(), 2000);
		} else {
			notify('error', response.data.message);
		}
	}

	return (
		<div className='d-flex flex-row gap-1'>
			<CustomModal
				buttonLabel={
					<div className='d-flex gap-1'>
						<div>Edit</div>
						<i className='bi bi-pencil'></i>
					</div>
				}
				className='btn btn-success btn-sm'
				title={'Update User'}
				body={<UpdateUserForm userId={userId} />}
			/>
			<button
				className='btn btn-secondary btn-sm d-flex gap-1'
				onClick={() => navigate(`/admin/user/${userId}`)}
			>
				<div>View</div>
				<i className='bi bi-eye-fill my-auto'></i>
			</button>
			<CustomModal
				buttonLabel={
					<div className='d-flex flex-row gap-2'>
						<div>Delete</div>
						<i className='bi bi-trash'></i>
					</div>
				}
				className={'btn btn-danger btn-sm d-flex gap-1'}
				title='Are you sure you want to proceed?'
				functionality={deleteUser}
			/>
			<ToastContainer
				position='top-right'
				hideProgressBar={false}
				autoClose={2000}
				pauseOnHover
				theme='colored'
			/>
		</div>
	);
}

export default UserManagementModals;
